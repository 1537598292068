@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

html {
    scroll-behavior: smooth;
}

:root {
    --primary: #201245;
    --secondary: #F8922E;
    --hover: #E7811D;
    --light: #8A6FD5;
}

button,
a {
    color: inherit;
    text-decoration: none;
    border: none;
    outline: none !important;
    cursor: pointer;
    transition: .2s all ease-in-out;
}

.w {
    width: 90%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.img {
    width: 100%;
    height: auto;
}

.container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero * {
    color: #fff;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero__top {
    position: relative;
    margin: auto;
    z-index: 4;
}

.logo {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 50px;
    font-size: 48px;
    width: 220px;
    margin-top: 40px;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
}

.hero__content {
    position: relative;
    align-items: flex-start;
    z-index: 4;
    width: 100%;
    margin: 0;
}

.hero__content > * {
    width: 46%;
    height: 100vh;
    max-height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.hero__content__left {
    padding-left: 80px;
}

.hero__content__header {
    margin: 15px 0;
    color: #fff;
    font-size: 44px;
    font-weight: 700;
}

.hero__content__subheader {
    font-weight: 400;
    font-size: 17px;
    color: #fff;
}

.hero__content__textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 300px;
    border-radius: 20px;
    padding: 30px;
    font-size: 17px;
    color: #313131;
    resize: none;
    background: #F3F4F7;
}

.btn {
    background: var(--secondary);
    color: #111;
    font-weight: 700;
    text-transform: uppercase;
}

.btn:hover {
    background: var(--hover);
}

.submitBtnWrapper {
    position: relative;
}

.submitBtnWrapper > * {
    transition: .2s all ease-in-out;
}

.btn--submit {
    width: 100%;
    height: 50px;
    border-radius: 40px;
    font-size: 18px;
    margin-top: 20px;
}

.loader {
    padding-top: 20px;
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero__content__right {
    position: absolute;
    top: 20vh;
    right: 10vw;
    padding: 50px;
    width: 40%;
    max-width: 600px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 11px -3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 11px -3px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 11px -3px rgba(66, 68, 90, 1);
    border-radius: 20px;
    z-index: 10;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    max-height: 1000px;
}

.background__img {
    position: absolute;
    left: 0;
    top: -20%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 120%;
    z-index: 2;
}

.backgroundOverlay {
    max-height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--primary);
    opacity: .8;
    z-index: 3;
}

.btn--submit,
.hero__content__textarea {
    position: relative;
    z-index: 5;
}

.backgroundBottom {
    position: absolute;
    top: 76vh;
    left: 0;
    width: 120vw;
    height: 400px;
    background: #fff;
    z-index: 7;
    transform: rotate(-5deg);
}

.btn--simple {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    text-align: center;
    border: none;
    font-size: 13px;
    margin: 20px auto 0;
    text-decoration: underline;
    color: var(--primary);
}

.section {
    position: relative;
    z-index: 10;
    padding: 0 10%;
    margin: 50px auto;
    width: 100%;
}

.section--arguments {
    position: relative;
    z-index: 21;
    margin-top: 100px;
}

.section__header {
    color: var(--primary);
    text-align: center;
    font-size: 44px;
    max-width: 1100px;
    margin: auto;
}

.hero__content__right__header {
    font-size: 19px;
    text-transform: uppercase;
    color: var(--primary);
    text-align: center;
    margin: auto;
    padding-bottom: 20px;
}

.chooseList,
.arguments {
    width: 100%;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
}

.shadow {
    -webkit-box-shadow: 0px 0px 6px -3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 6px -3px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 6px -3px rgba(66, 68, 90, 1);
}

.chooseList__item,
.arguments__item {
    background: #fff;
    position: relative;
    width: 100%;
    height: auto;
    padding: 25px;
    border-radius: 20px;
    border: 2px solid transparent;
    transition: .05s all ease-in !important;
}

.arguments__item__img {
    width: 100%;
    max-height: 150px;
    overflow: hidden;
}

.arguments__item__title {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 700;
}

.chooseList--small .arguments__item__title {
    margin-top: 0;
    margin-bottom: 0 !important;
}

.arguments__item__extract {
    font-size: 14px;
    margin-top: -5px;
}

.btn--arguments {
    width: 38%;
    height: 40px;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 12px;
}

.btn--selectArgument {
    height: 40px;
    border-radius: 40px;
    background: transparent;
    border: 2px solid var(--light);
    width: 55%;
    margin-top: 20px;
}

.btn--selectArgument > .icon {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.btn--selectArgument > .icon--cancel {
    width: 15px;
    margin-right: 10px;
}

.btn--selectArgument:hover {
    background: #efefef;
}

.btn--selectArgument:active,
.btn--selectArgument:focus {
    background: transparent !important;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.modal__inner {
    max-width: 1600px;
    background: #fff;
    padding: 40px;
    width: 80%;
    height: auto;
    max-height: 80%;
    overflow-y: auto;
}

.modal__inner__header {
    font-size: 32px;
    color: var(--primary);
    text-align: center;
}

.modal__inner__media__img,
.video-container {
    position: relative;
    padding-bottom: 26.25%; /* 16:9 */
    height: 0;
}

.modal__inner__media__img > .img,
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal__inner__contentHeader {
    margin: 40px auto 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}

.btn--close {
    background: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    border: none;
    outline: none;
}

.modal__inner__media {
    margin: 20px auto;
}

.modal__inner__media > * {
    width: 48%;
}

.scroll {
    scrollbar-width: thin;
    scrollbar-color: var(--light) #fff;
}

.scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.scroll::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
    background: var(--primary);
    border: 33px none var(--primary);
    border-radius: 36px;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}

.scroll::-webkit-scrollbar-thumb:active {
    background: var(--primary);
}

.scroll::-webkit-scrollbar-track {
    background: var(--light);
    border: 49px none var(--primary);
    border-radius: 50px;
}

.scroll::-webkit-scrollbar-corner {
    background: transparent;
}

.arguments__item__badge {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px;
    font-size: 13px;
    background: var(--light);
    border-radius: 10px;
    z-index: 100;
    color: #fff;
    text-transform: lowercase;
}

.error {
    height: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-size: 14px;
    text-align: center;
}

.loadingPage {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.chooseArgumentsHeader {
    text-align: center;
    margin: 25px auto 50px;
    font-size: 32px;
}

.chooseArgumentsHeader--responsePage {
    font-size: 24px;
}

.chooseListWrapper {
    margin-top: 100px;
    position: relative;
    z-index: 20;
}

.chooseList {
    margin-top: 80px;
    position: relative;
    z-index: 10;
}

.chooseList__item {
    padding: 5px 25px;
    background: transparent;
    position: relative;
    border: 2px solid transparent;
    gap: 10%;
    justify-content: flex-start;
}

.arguments__item:hover,
.chooseList__item:hover {
    background: #f9f9f9;
}

.arguments__item:focus,
.arguments__item:active,
.chooseList__item:focus,
.chooseList__item:active {
    background: transparent !important;
}

.chooseList__item--selected {
    border: 2px solid #00dd00;
}

.chooseList__item__image {
    width: 90px;
    height: 70px;
    min-width: 90px;
    min-height: 70px;
}

.chooseList__item__check {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: auto;
}

.chooseList__item__check--small {
    width: 30px;
    top: -15px;
    right: -15px;
}

.chooseList__item .arguments__item__title {
    font-weight: 500;
    font-size: 15px;
    text-align: left;
}

.btn--submitBig {
    width: 100%;
    height: 60px;
    border-radius: 60px;
    margin: 30px auto;
}

.chooseArgumentsHeading .backgroundOverlay {
    height: 400px;
}

.chooseArgumentsHeading .backgroundBottom {
    height: 150px;
    top: 340px;
    transform: rotate(2deg);
}

.chooseArgumentsHeading .background {
    height: 400px;
}

.chooseArgumentsHeading .hero__content__right {
    box-shadow: none;
    color: #fff;
    height: auto;
    width: auto;
    background: transparent;
    top: 10px;
    margin-left: 600px;
}

.chooseArgumentsHeading .hero__content__left {
    height: 300px;
}

.btn--submitBig,
.btn--addOwnArguments {
    position: relative;
    z-index: 12;
}

.btn--submitBig:disabled:hover {
    background: var(--secondary);
}

.btn--submitBig:disabled {
    opacity: .8;
    cursor: default;
}

.chooseList--foundByAi .chooseList__item {
    cursor: default;
}

.chooseList--foundByAi .chooseList__item:hover {
    transform: none;
}

.preview {
    padding-bottom: 100px;
    margin-top: 100px;
    z-index: 10;
    position: relative;
    align-items: flex-start;
}

.preview__left,
.preview__right {
    width: 48%;
}

.preview__header {
    font-size: 24px;
    text-align: center;
}

.preview__content {
    position: relative;
    padding: 20px;
    border: 2px solid var(--primary);
    border-radius: 10px;
    margin: 20px auto;
    width: 100%;
}

.preview__content > a {
    text-decoration: underline;
    color: #8A6FD5;
}

.preview__content--link {
    margin-top: 40px;
}

.preview__content--link a {
    overflow-wrap: break-word;
}

.preview__contentWrapper {
    position: relative;
}

.preview__content__link {
    justify-content: flex-start;
    gap: 10px;
    border: 1px solid var(--light);
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}

.preview__content__link__text {
    font-size: 14px;
    max-width: calc(100% - 80px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.preview__content__link__button {
    width: 25px;
    height: 25px;
    border: 1px solid #313131;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
}

.preview__content__link__button:hover {
    background: #f1f1f1;
}

.preview__content__link__button > .img {
    height: 12px;
    width: auto;
}

.btn--copy {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background: #fff;
    z-index: 20;
    border: 2px solid var(--secondary);
    border-radius: 5px;
    transition: .2s all ease-in-out;
}

.btn--copy:hover {
    background: var(--secondary);
}

.btn--copy:hover > .img {
    filter: brightness(0) invert(1);
}

.btn--copy > .img {
    transition: .2s all ease-in-out;
    width: 60%;
    height: auto;
}

.preview__right__text {
    font-size: 14px;
    text-align: center;
    margin: 20px auto 15px;
    max-width: 400px;
}

.preview__right__form {
    width: 500px;
    margin: 15px auto;
}

.preview__right__form > .input {
    width: 300px;
    height: 40px;
    border: 1px solid var(--primary);
    padding: 5px 10px;
    font-size: 15px;
}

.btn--preview {
    width: calc(100% - 320px);
    height: 40px;
    background: var(--secondary);
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .2s all ease-in-out;
}

.btn--preview:hover {
    background: var(--hover);
}

.btn--preview:disabled {
    opacity: .8;
    cursor: default;
}

.preview__switchWrapper {
    margin: 40px auto;
    gap: 20px;
}

.preview__switchWrapper > p {
    width: auto;
    font-size: 13px;
}

.section--static {
    z-index: 3;
    margin: 100px auto;
}

.section__header {
    color: var(--primary);
    font-size: 44px;
    text-align: center;
    margin: 20px auto;
    max-width: 1000px;
}

.section__text {
    display: block;
    width: 100%;
    margin: 30px auto;
    text-align: center;
}

.footer {
    padding: 15px 0;
    border-top: 1px solid #C8C8C8;
}

.footer__header {
    color: #7E7E7E;
    font-size: 12px;
    font-weight: 400;
}

.footer__links {
    justify-content: flex-end;
    gap: 15px;
}

.footer__links > a {
    display: block;
    height: 25px;
    width: auto;
    overflow: hidden;
    transition: .2s all;
}

.footer__links > a:hover {
    opacity: .75;
}

.footer__links .img {
    height: 100%;
    width: auto;
}

.footer__links > a:first-child {
    border-radius: 50%;
}

.copied {
    background: var(--secondary);
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    padding: 10px 50px;
    border-radius: 5px;
    display: block;
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    transition: .5s all ease-in-out;
    z-index: 100;
}

.preview__right__checkbox {
    justify-content: center;
    gap: 10px;
    font-size: 13px;
    cursor: pointer;
    margin: 20px auto;
    max-width: 500px;
}

.btn--checkbox {
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid #000;
    padding: 5px;
}

.playlist {
    overflow: hidden;
    height: 440px;
}

.playlist__main {
    width: 60%;
}

.playlist__main--only {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.playlist__main__title {
    background: var(--primary);
    color: #fff;
    font-size: 21px;
    padding: 10px 30px;
}

.playlist__main__iframe {
    width: 100%;
}

.playlist__list {
    padding-right: 10px;
    height: 100%;
    width: 38%;
    overflow-y: auto;
    align-items: flex-start;
}

.playlist__list__item {
    overflow: hidden;
    border-radius: 10px;
    background: transparent;
    width: 100%;
    height: 80px;
    padding-right: 20px;
    border: 1px solid var(--primary);
    margin-bottom: 10px;
    font-size: 16px;
    transition: .2s all ease-in-out;
}

.playlist__list__item:not(.playlist__list__item--current):hover {
    background: #efefef;
}

.playlist__list__item--current {
    background: var(--secondary);
}

.playlist__list__item__img {
    height: 100%;
    width: auto;
    margin-right: 25px;
}

.playlist__list__item__img > .img {
    height: 100%;
    width: auto;
}

.playlist .video-container {
    padding-bottom: 58%;
}

.preview--response {
    flex-direction: column;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 270px;
}

.preview--response > * {
    width: 100%;
}

.preview--response .preview__right {
    order: -1;
    margin-bottom: 50px;
}

.counter {
    opacity: 0;
    background: #fff;
    position: fixed;
    bottom: 40px;
    right: 40px;
    -webkit-box-shadow: 0px 0px 11px -3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 11px -3px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 11px -3px rgba(66, 68, 90, 1);
    padding: 20px;
    border-radius: 25px;
    z-index: 100;
    transition: .6s opacity ease-in-out;
}

.counter__text {
    font-size: 15px;
    font-weight: 500;
}

.counter__text > span {
    display: block;
    text-align: center;
    width: 30px;
    font-size: 32px;
    font-weight: 700;
    color: var(--secondary);
    padding-left: 8px;
}

.btn--counter {
    margin-top: 20px;
    width: 200px;
    height: 40px;
    border-radius: 40px;
}

.section__choice {
    margin: 30px auto;
    gap: 50px;
}

.btn--view {
    background: transparent;
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    opacity: .4;
}

.btn--view:hover {
    background: transparent;
    transform: translateY(-2px);
}

.btn--view--selected {
    opacity: 1;
}

.btn--view > .img {
    width: 20px;
    height: auto;
}

.chooseArgumentsHeading--responsePage {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.chooseArgumentsHeading--responsePage .background,
.chooseArgumentsHeading--responsePage .backgroundOverlay {
    height: 100%;
}

.chooseArgumentsHeader--responsePage {
    position: relative;
    z-index: 100;
    color: #fff;
    padding: 30px 0;
    max-width: 850px;
}

@media(max-width: 1600px) {
    .hero__content > * {
        width: 52%;
    }

    .hero__content__right {
        width: 500px;
    }
}

@media(max-width: 1400px) {
    .chooseListWrapper {
        margin-top: 20px;
    }

    .chooseListWrapper > .section__choice {
        margin-bottom: 50px;
    }

    .preview__header {
        font-size: 19px;
    }

    .preview__content {
        font-size: 14px;
    }

    .preview {
        margin-top: 60px;
    }

    .btn--copy {
        width: 40px;
        height: 40px;
        top: -15px;
        right: -15px;
    }

    .preview__right__text {
        font-size: 13px;
    }

    .btn--preview {
        font-size: 13px;
    }

    .preview__right__form {
        width: 300px;
        flex-direction: column;
    }

    .preview__right__form > * {
        width: 100%;
        max-width: 300px;
    }

    .preview__right__form > input {
        margin-bottom: 10px;
    }

    .backgroundBottom {
        display: none;
    }

    .hero__content__header {
        font-size: 32px;
    }

    .hero__content__textarea,
    .hero__content__right__header,
    .hero__content__subheader {
        font-size: 14px;
    }

    .hero__content__textarea {
        padding: 20px;
    }

    .btn--simple {
        font-size: 12px;
    }

    .hero__content__right {
        width: 460px;
        right: 80px;
    }

    .btn--submit {
        font-size: 15px;
        height: 40px;
    }

    .chooseArgumentsHeading:not(.chooseArgumentsHeading--responsePage) .backgroundOverlay,
    .chooseArgumentsHeading:not(.chooseArgumentsHeading--responsePage) .background {
        height: 280px;
    }

    .chooseArgumentsHeader {
        margin: 20px auto 0;
        font-size: 24px;
        width: 400px;
    }

    .chooseList {
        margin-top: 30px;
    }

    .chooseArgumentsHeader--responsePage {
        font-size: 21px;
    }

    .arguments__item > .flex {
        flex-direction: column;
        gap: 20px;
    }

    .arguments__item > .flex > * {
        width: 100%;
        margin: 0;
    }

    .arguments__item > .flex > *:first-child {
        margin-top: 20px;
    }

    .preview--response {
        margin-top: 270px;
    }
}

@media(max-width: 1200px) {
    .modal__inner__header {
        font-size: 24px;
    }

    .hero__content > * {
        width: 44%;
    }

    .hero__content__header {
        font-size: 24px;
    }

    .modal__inner__contentHeader {
        font-size: 19px;
    }

    .modal__inner__content {
        font-size: 14px;
    }

    .section__header {
        font-size: 32px;
    }

    .chooseList,
    .arguments {
        grid-gap: 30px;
        grid-template-columns: repeat(2, 1fr);
    }

    .playlist {
        height: 350px;
    }
}

@media(max-width: 996px) {
    .chooseList,
    .arguments {
        grid-gap: 30px;
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .chooseList {
        margin-top: 0;
    }

    .chooseListWrapper {
        margin-top: 140px;
    }

    .section__header,
    .arguments__item {
        max-width: 400px;
    }

    .section__header {
        font-size: 24px;
    }

    .section--static {
        margin-bottom: 20px;
    }

    .modal__inner__media {
        flex-direction: column;
    }

    .modal__inner__media > * {
        width: 100%;
    }

    .modal__inner__media__img {
        display: none;
    }

    .video-container {
        padding-bottom: 56%;
    }

    .modal__inner__header {
        font-size: 15px;
    }

    .modal__inner__contentHeader {
        font-size: 14px;
        margin: 20px auto;
    }

    .modal__inner__content {
        font-size: 12px;
    }

    .preview {
        flex-direction: column;
    }

    .preview > div {
        width: 100%;
    }

    .preview__right {
        margin-top: 30px;
    }

    .playlist {
        height: auto;
        flex-direction: column;
    }

    .playlist .playlist__main {
        margin-bottom: 30px;
    }

    .playlist > * {
        width: 100%;
    }

    .playlist__list {
        max-height: 300px;
    }

    .hero__content__right {
        position: relative;
        top: unset;
        right: unset;
    }

    .hero {
        position: relative;
        margin-bottom: 280px;
    }

    .backgroundOverlay,
    .background {
        height: 100% !important;
        max-height: none !important;
    }

    .hero__content > * {
        width: 100%;
    }

    .logo {
        width: 120px;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
    }

    .hero__content {
        margin-bottom: -220px;
    }

    .hero__content > * {
        height: auto;
        margin-top: 50px;
    }

    .hero__content__left {
        z-index: 11;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .hero__content__left * {
        text-align: center;
    }

    .hero__content__right {
        margin: 0 auto;
        transform: translateY(220px);
    }

    .chooseArgumentsHeading:not(.chooseArgumentsHeading--responsePage) .backgroundOverlay,
    .chooseArgumentsHeading:not(.chooseArgumentsHeading--responsePage) .background {
        height: 200px !important;
    }

    .chooseArgumentsHeading .hero__content {
        flex-direction: column;
    }

    .chooseArgumentsHeading .hero__content__left {
        height: 10px;
    }

    .chooseArgumentsHeading .hero__content__right {
        position: relative;
        top: unset;
        transform: none;
        margin: -115px auto 100px;
    }

    .preview {
        margin-top: 155px;
    }

    .chooseArgumentsHeader--responsePage {
        font-size: 16px !important;
    }

    .container--responsePage .preview--response {
        margin-top: 150px;
    }

    .preview__content__link__text {
        font-size: 12px;
    }

    .chooseArgumentsHeader--responsePage {
        margin-top: 0;
        max-width: 90%;
    }
}

@media(max-width: 768px) {
    .section__text,
    .arguments__item__title {
        font-size: 14px;
    }

    .btn--selectArgument,
    .arguments__item__title {
        font-size: 12px !important;
    }

    .btn--addOwnArguments {
        margin-bottom: 25px;
    }

    .btn--arguments,
    .arguments__item__badge,
    .arguments__item__extract {
        font-size: 12px;
    }

    .modal__inner {
        padding: 20px;
    }

    .modal__inner__content {
        text-align: center;
    }

    .btn--submitBig {
        margin-top: 0;
        font-size: 14px;
    }

    .chooseList__item__check {
        top: -10px;
        right: -10px;
        width: 35px;
    }

    .preview__right__checkbox,
    .preview__right__text,
    .copied,
    .preview__switchWrapper > p,
    .preview__content {
        font-size: 12px;
    }

    .copied {
        text-align: center;
    }

    .preview__right__form input {
        font-size: 13px;
    }

    .preview__right__checkbox,
    .preview__right__form,
    .preview__right__form > * {
        max-width: 245px !important;
        width: 100% !important;
    }

    .preview {
        padding-bottom: 40px;
    }

    .counter {
        padding: 15px;
        bottom: 20px;
        right: 20px;
    }

    .counter__text {
        font-size: 12px;
    }

    .counter__text > span {
        font-size: 18px;
        width: 10px;
    }

    .btn--counter {
        margin-top: 15px;
        width: 150px;
        height: 30px;
        font-size: 12px;
        border-radius: 30px;
    }
}

@media(max-width: 576px) {
    .section__choice {
        flex-direction: column;
        gap: 20px;
        margin: 40px auto;
    }

    .container--responsePage .preview--response {
        margin-top: 125px;
    }

    .footer {
        padding-top: 10px;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    .footer__links {
        margin-top: 20px;
    }

    .modal__inner {
        width: 90%;
        height: 80%;
    }

    .btn--close {
        top: 2px;
        right: 15px;
    }

    .playlist__list__item__title {
        font-weight: 500;
        font-size: 13px;
    }

    .playlist__list__item {
        height: 50px;
    }

    .preview__left {
        margin-top: -30px;
    }

    .hero__content__right {
        width: 90%;
        padding: 30px;
    }

    .hero__content__left {
        padding: 40px 20px 30px;
    }

    .chooseArgumentsHeading .hero__content__right {
        margin-top: -70px;
    }

    .chooseListWrapper {
        margin-top: 170px;
    }

    .preview {
        margin-top: 220px;
    }

    .chooseArgumentsHeader {
        font-size: 19px;
        width: 100%;
        text-align: center;
    }

    .chooseArgumentsHeading--response .chooseArgumentsHeader {
        padding-top: 20px;
    }

    .chooseArgumentsHeader--responsePage {
        font-size: 14px !important;
    }
}

@media(max-width: 450px) {
    .chooseArgumentsHeader {
        font-size: 16px;
    }

    .chooseArgumentsHeader--responsePage {
        font-size: 12px !important;
    }
}

@media(min-width: 2000px) {
    .backgroundBottom {
        display: none;
    }

    .hero__content__left {
        height: 1000px !important;
        padding-left: calc((100vw - 1400px) / 2);
    }

    .logo {
        left: calc((100vw - 1400px) / 2);
    }

    .hero__content__right {
        right: calc((100vw - 1400px) / 2);
        width: 700px;
        top: 250px;
    }

    .chooseArgumentsHeading .logo {
        left: 10vw;
    }

    .chooseArgumentsHeading .hero__content__left {
        padding-left: 10vw;
    }

    .chooseArgumentsHeading .hero__content__right {
        right: 10vw;
    }

    .chooseArgumentsHeading .hero__content__left {
        height: 250px !important;
    }

    .chooseArgumentsHeading:not(.chooseArgumentsHeading--responsePage) .backgroundOverlay,
    .chooseArgumentsHeading:not(.chooseArgumentsHeading--responsePage) .background {
        height: 300px;
    }
}
